"use client";
import { useState, useRef, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import { Button } from "@/components/tailwind/ui/button";
import Menu from "@/components/tailwind/ui/menu";
import { Printer, X, Plus } from "lucide-react";

const firebaseConfig = {
  apiKey: "AIzaSyDEkmpcHOb6Qm0sja3ecFnwiNvQJf4-ubk",
  authDomain: "samugen-81f3c.firebaseapp.com",
  projectId: "samugen-81f3c",
  storageBucket: "samugen-81f3c.appspot.com",
  messagingSenderId: "905196662319",
  appId: "1:905196662319:web:1477066b3794e34b6520da",
  measurementId: "G-JLH1Z8KLK0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function Page() {
  const [language, setLanguage] = useState("Korean");
  const [style, setStyle] = useState("Professional");
  const [files, setFiles] = useState([]);
  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const [fileDropdownOpen, setFileDropdownOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleAuth = () => {
    if (isSignUp) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handlePrintClick = () => {
    const element = document.getElementById("editor-content");
    // if (element && html2pdf) {
    // const opt = {
    //   margin: 1,
    //   filename: "document.pdf",
    //   image: { type: "jpeg", quality: 0.98 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    // };

    // html2pdf().set(opt).from(element).save();
    // }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    for (const file of selectedFiles) {
      const text = await (file as File).text();
      const chunks = chunkText(text, 500); // Chunk the text into 500 character chunks

      for (const chunk of chunks) {
        const response = await fetch('http://localhost:3000/api/embed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ model: "chatfire/bge-m3:q8_0", prompt: chunk }),
        });

        if (response.ok) {
          const { embedding } = await response.json();
          console.log("Embedding for chunk:", chunk);
          console.log("Embedding vector:", embedding);
          // Store the embedding vector as needed
        } else {
          console.error('Error generating embedding:', response.statusText);
        }
      }
    }
  };

  const chunkText = (text, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < text.length; i += chunkSize) {
      chunks.push(text.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleShortcutsClick = () => {
    setShortcutsOpen(true);
  };

  const handleShortcutsClose = () => {
    setShortcutsOpen(false);
  };

  const toggleFileDropdown = () => {
    setFileDropdownOpen(!fileDropdownOpen);
  };

  if (!user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-2xl mb-4">{isSignUp ? "Sign Up" : "Login"}</h1>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-2 p-2 border rounded"
        />
        <Button onClick={handleAuth}>{isSignUp ? "Sign Up" : "Login"}</Button>
        <Button onClick={() => setIsSignUp(!isSignUp)} className="mt-2">
          {isSignUp ? "Switch to Login" : "Switch to Sign Up"}
        </Button>
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen flex-col items-center gap-4 py-4 sm:px-5">
      <div className="flex flex-col w-full max-w-screen-lg items-center gap-2 px-4">
        <div className="relative w-full flex flex-col items-start mt-4 gap-2">
          <div className="absolute top-0 right-0 flex items-center">
            <Button
              className="text-xs px-3 rounded"
              variant="ghost"
              onClick={toggleFileDropdown}
            >
              백데이터 목록
            </Button>
            {fileDropdownOpen && (
              <div className="absolute top-full mt-1 w-48 bg-white shadow-md rounded border border-gray-200 z-10">
                <ul className="max-h-40 overflow-y-auto">
                  {files.map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between px-2 py-1"
                    >
                      <span className="text-xs">{file.name}</span>
                      <Button
                        className="text-xs p-0 rounded"
                        variant="ghost"
                        onClick={() => handleRemoveFile(file.name)}
                      >
                        <X width={16} />
                      </Button>
                    </li>
                  ))}
                  <li className="flex justify-center p-2">
                    <Button
                      className="text-xs p-0 rounded px-4"
                      variant="ghost"
                      onClick={handleFileClick}
                    >
                      <Plus width={16} />
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      multiple
                    />
                  </li>
                </ul>
              </div>
            )}
            <Button
              className="text-xs px-3 rounded"
              variant="ghost"
              onClick={handlePrintClick}
            >
              <Printer width={16} />
            </Button>
            <Menu />
          </div>

          <div className="flex gap-2 items-center">
            <Button
              className="text-xs px-3 rounded-3xl"
              variant={language === "Korean" ? "default" : "outline"}
              onClick={() => setLanguage("Korean")}
            >
              Korean
            </Button>
            <Button
              className="text-xs px-3 rounded-3xl"
              variant={language === "English" ? "default" : "outline"}
              onClick={() => setLanguage("English")}
            >
              English
            </Button>
          </div>
        </div>
        <div id="editor-content" className="w-full">
          <TailwindAdvancedEditor language={language} />
        </div>
      </div>
    </div>
  );
}
