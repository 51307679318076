import {
  Pencil,
  Type,
  StepForward,
  Terminal,
  AlignLeft,
  List,
  FileText,
  Minimize2,
  Maximize2,
} from "lucide-react";
import { useEditor } from "novel";
import { getPrevText } from "novel/utils";
import { CommandGroup, CommandItem, CommandSeparator } from "../ui/command";

const options = [
  {
    value: "improve",
    label: "글 개선",
    icon: Pencil,
  },
  // {
  //   value: "fix",
  //   label: "문법 수정",
  //   icon: CheckCheck,
  // },
  {
    value: "seedtitle",
    label: "제목으로 변환",
    icon: Type,
  },
  {
    value: "seedsentence",
    label: "문장으로 변환",
    icon: Terminal,
  },
  {
    value: "seedparagraph",
    label: "문단으로 변환",
    icon: AlignLeft,
  },
  {
    value: "seedlist",
    label: "리스트로 변환",
    icon: List,
  },
  {
    value: "seedblueprint",
    label: "문서화",
    icon: FileText,
  },
  {
    value: "shorter",
    label: "더 짧게",
    icon: Minimize2,
  },
  {
    value: "longer",
    label: "더 길게",
    icon: Maximize2,
  },
];

interface AISelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const AISelectorCommands = ({ onSelect }: AISelectorCommandsProps) => {
  const { editor } = useEditor();

  return (
    <>
      <CommandGroup heading="선택 항목 편집/검토">
        {options.map((option) => (
          <CommandItem
            onSelect={(value) => {
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(
                slice.content
              );
              onSelect(text, value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-blue-700" />
            {option.label}
          </CommandItem>
        ))}
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup heading="사무젠으로 더 많은 일 하기">
        <CommandItem
          onSelect={() => {
            const pos = editor.state.selection.from;

            const text = getPrevText(editor, pos);
            onSelect(text, "continue");
          }}
          value="continue"
          className="gap-2 px-4"
        >
          <StepForward className="h-4 w-4 text-blue-700" />글 이어쓰기
        </CommandItem>
      </CommandGroup>
    </>
  );
};

export default AISelectorCommands;
